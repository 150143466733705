import { gql } from "@apollo/client"

export const CATEGORY_PLACE_FRAGMENT = gql(`
  fragment CategoryPlaceFragment on CategoryPlace {
    id
    name
    name_en
    name_mg
    name_wl
    name_dj
    coverImage {
      url
      id
    }
    icon {
      id
      url
    }
  }
`)
