import React, { useState, useMemo, useEffect } from "react"
import { useQuery, useMutation, useSubscription } from "@apollo/client"
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  Chip,
} from "@mui/material"
import { Close, CheckCircle, RadioButtonUnchecked } from "@mui/icons-material"
import { graphql } from "../../../../gql"
import {
  Event,
  Exact,
  InputMaybe,
  InvitationStatus,
  QueryMode,
  Scalars,
  SortOrder,
  UserOrderByWithRelationAndSearchRelevanceInput,
  UserWhereInput,
} from "../../../../gql/graphql"
import { useAppDispatch } from "../../../../store/app/hooks"
import { getErrorsAsString } from "../../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../../store/features/snackbar/snackbarSlice"
import { updateCacheEvent } from "../../../../caches/updateCacheEvent"

const USERS_CONTROLLERS = graphql(`
  query Users(
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $aggregateUserWhere2: UserWhereInput
  ) {
    users(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name
      lastName
      email
    }
    aggregateUser(where: $aggregateUserWhere2) {
      _count {
        _all
      }
    }
  }
`)

const ADD_CONTROLLERS = graphql(`
  mutation AddMediators($eventId: Float!, $userIds: [Int!]!) {
    addMediators(eventId: $eventId, userIds: $userIds) {
      eventId
      id
      invitationStatus
      user {
        id
        name
        lastName
        email
      }
    }
  }
`)

const ChangeMediators = graphql(`
  subscription SubsAddMediators($key: String!, $eventId: Int!) {
    subsAddMediators(key: $key, eventId: $eventId) {
      id
      latitude
      longitude
      numberPeopleMax
      isFreeEvent
      isEventCommissionPaid
      title
      isPublish
      isValidate
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      isFree
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

interface Props {
  open: boolean
  handleClose: () => void
  event: Event
}

const AddControllers: React.FC<Props> = ({ open, event, handleClose }) => {
  const [hasMore, setHasMore] = useState(true)
  const [query, setQuery] = useState("")
  const [userIds, setUserIds] = useState<number[]>([])
  const dispatch = useAppDispatch()
  const size = 5

  const [addControllers, { loading: isLoading }] = useMutation(ADD_CONTROLLERS)

  const toggleItem = (id: number) => {
    setUserIds((prevUserIds) =>
      prevUserIds.includes(id)
        ? prevUserIds.filter((itemId) => itemId !== id)
        : [...prevUserIds, id],
    )
  }

  useSubscription(ChangeMediators, {
    variables: {
      eventId: event.id,
      key: "CHANGE_EVENT_CONTROLLER",
    },
    onData: ({ client, data }) => {
      const newEvent = data.data?.subsAddMediators
      updateCacheEvent({
        action: "update",
        cache: client.cache,
        entryData: newEvent,
      })
    },
  })

  const variables:
    | Exact<{
        where?: InputMaybe<UserWhereInput>
        orderBy?: InputMaybe<
          | Array<UserOrderByWithRelationAndSearchRelevanceInput>
          | UserOrderByWithRelationAndSearchRelevanceInput
        >
        take?: InputMaybe<Scalars["Int"]["input"]>
        skip?: InputMaybe<Scalars["Int"]["input"]>
        aggregateUserWhere2?: InputMaybe<UserWhereInput>
      }>
    | undefined = useMemo(
    () => ({
      orderBy: [{ id: SortOrder.Asc }],
      where: {
        id: {
          not: {
            equals: event.creator.id,
          },
        },
        ...(query && {
          OR: [{ id: { equals: Math.abs(Number(query)) } }],
        }),
      },
      aggregateUserWhere2: {
        id: {
          not: {
            equals: event.creator.id,
          },
        },
        ...(query && {
          OR: [{ id: { equals: Number(query) } }],
        }),
      },
      take: size,
      skip: 0,
    }),
    [query, event.creator.id],
  )

  const { data, loading, fetchMore, previousData } = useQuery(
    USERS_CONTROLLERS,
    {
      variables,
      onCompleted: (fetchedData) => {
        if (
          fetchedData?.users.length >=
          (fetchedData.aggregateUser._count?._all as number)
        ) {
          setHasMore(false)
        }
      },
    },
  )

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget

    if (scrollHeight - scrollTop <= clientHeight + 100 && hasMore && !loading) {
      fetchMore({
        variables: { skip: data?.users.length },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult?.users.length) {
            setHasMore(false)
            return previousResult
          }
          return {
            ...previousResult,
            users: [...previousResult.users, ...fetchMoreResult.users],
          }
        },
      })
    }
  }

  const handleAddMediator = () => {
    if (!isLoading) {
      addControllers({
        variables: {
          userIds,
          eventId: event.id,
        },
        onCompleted() {
          handleClose()
          dispatch(
            setOpenSnackbar({
              message: "Modification réussie !!!",
              status: "success",
            }),
          )
        },
        update(cache, { data }) {
          cache.modify({
            id: cache.identify({ __typename: "Event", id: event.id }),
            fields: {
              mediators: () => data?.addMediators || [],
            },
          })
        },
        onError(error) {
          handleClose()
          const message = getErrorsAsString(error)
          dispatch(setOpenSnackbar({ message }))
        },
      })
    }
  }

  const getState = (userId: number) => {
    if (userIds.includes(userId)) {
      const state = event.mediators.find((el) => el.user.id === userId)
      if (state) {
        if (state.invitationStatus === InvitationStatus.Valid) {
          return "Validé"
        }
        if (state.invitationStatus === InvitationStatus.Denied) {
          return "Refusée"
        }
        return "En attente de validation"
      }
    }
    return ""
  }

  useEffect(() => {
    if (event?.mediators) {
      const users = event?.mediators.flatMap((el) => el.user.id)
      setUserIds(users)
      setHasMore(true)
    }
    return () => {
      setUserIds([])
      setHasMore(true)
    }
  }, [event])

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isLoading) {
          handleClose()
        }
      }}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: "#333333",
        },
      }}
    >
      <DialogTitle>
        Ajouter des contrôleurs: {event.title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          value={query}
          onChange={(e) => {
            setHasMore(true)
            setQuery(e.target.value)
          }}
          placeholder="Rechercher..."
          margin="normal"
          type="number"
        />
        {loading && !previousData?.users.length ? (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress color="secondary" size={25} />
          </Box>
        ) : (
          <List
            sx={{ mt: 2, maxHeight: 400, overflow: "auto" }}
            onScroll={handleScroll}
          >
            {data?.users?.map((item: any) => (
              <ListItem
                sx={{
                  alignItems: "center",
                  cursor: "pointer",
                  ":hover": {
                    background: "rgba(0,0,0,0.5)",
                    borderRadius: 5,
                  },
                }}
                key={`user:${item?.id}-event:${event.id}`}
                onClick={() => toggleItem(item.id)}
              >
                <ListItemIcon>
                  {userIds.includes(item.id) ? (
                    <CheckCircle color="success" />
                  ) : (
                    <RadioButtonUnchecked />
                  )}
                </ListItemIcon>
                <ListItemText
                  secondaryTypographyProps={{
                    sx: { mt: 1, color: "whitesmoke" },
                  }}
                  primary={item.lastName || item.name || item.email}
                  secondary={`ID: ${item.id}`}
                />
                {getState(item.id) && (
                  <ListItemIcon>
                    <Chip label={getState(item.id)} variant="outlined" />
                  </ListItemIcon>
                )}
              </ListItem>
            ))}
            {loading && hasMore && (
              <Box display="flex" justifyContent="center" my={2}>
                <CircularProgress color="secondary" size={25} />
              </Box>
            )}
          </List>
        )}
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error" variant="contained">
          Annuler
        </Button>
        <Button variant="contained" onClick={handleAddMediator} color="success">
          {isLoading ? "Traitement..." : "Valider"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddControllers
