import * as React from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { useAppDispatch } from "../../store/app/hooks"
import { useNavigate } from "react-router-dom"
import { setOpenSnackbar } from "../../store/features/snackbar/snackbarSlice"
import { getErrorsAsString } from "../../utils/getErrorsAsString"
import { graphql } from "../../gql"
import { useMutation } from "@apollo/client"
import { Role, User } from "../../gql/graphql"
import {
  setAuthToken,
  setUserCountryId,
  setUserEmail,
  setUserId,
  setUserName,
} from "../../utils/authToken"
import { getStringEmpty } from "../../utils/methodeString"
import { setUser } from "../../store/features/user/userSlice"

// Signin mutation
const signinMutation = graphql(`
  mutation login($userLoginInput: UserLoginInput!) {
    login(userLoginInput: $userLoginInput) {
      token
      user {
        id
        email
        lastName
        name
        Country {
          id
        }
      }
    }
  }
`)

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="#">
        Le Hub
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export default function Login() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [signin, { loading }] = useMutation(signinMutation)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()
      const data = new FormData(event.currentTarget)

      signin({
        variables: {
          userLoginInput: {
            email: data.get("email")?.toString() as string,
            password: data.get("password")?.toString() as string,
            isBO: true,
            role: Role.Admin,
          },
        },
        onError: (error) => {
          const message = getErrorsAsString(error)
          dispatch(setOpenSnackbar({ message }))
        },
        onCompleted: ({ login }) => {
          dispatch(setUser(login?.user as User))
          setUserName(
            `${login.user.name} ${getStringEmpty(login.user.lastName)}`,
          )
          setUserId(login?.user.id || 0)
          setAuthToken(login.token)
          setUserEmail(login.user.email || "")
          setUserCountryId(login.user.Country?.id.toString() || "")
          window.location.href = "/"
        },
      })
    } catch (error: any) {
      const message = getErrorsAsString(error)
      dispatch(setOpenSnackbar({ message }))
    }
  }

  return (
    <Container
      sx={{ backgroundColor: "#000", borderRadius: 2 }}
      component="main"
      maxWidth="xs"
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          pt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, background: "dimgrey" }}
          >
            Se connecter
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#" color={"#fff"} variant="body2">
                Mot de passe oublié ?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5, pb: 2 }} />
    </Container>
  )
}
