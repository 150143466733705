import { useCallback, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useMutation, useQuery } from "@apollo/client"
import {
  selectEventCategoryPagination,
  setQueryEventCategory,
  setPaginationEventCategory,
  selectEventCategoryQuery,
} from "../../../store/features/eventCategory/eventCategorySlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { SortOrder, City, QueryMode } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { updateCacheCategory } from "../../../caches/updateCacheCategory"

export const LIST_CITY = graphql(`
  query Cities(
    $where: CityWhereInput
    $aggregateCityWhere2: CityWhereInput
    $orderBy: [CityOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    cities(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name_fr
      name_en
      name_mg
      name_wl
      name_dj
      latitude
      longitude
      country {
        id
        name_fr
      }
    }
    aggregateCity(where: $aggregateCityWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_CITY = graphql(`
  mutation DeleteOneCity($where: CityWhereUniqueInput!) {
    deleteOneCity(where: $where) {
      id
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyCity($where: CityWhereInput) {
    deleteManyCity(where: $where) {
      count
    }
  }
`)

const ListCity = () => {
  const { page, size } = useAppSelector(selectEventCategoryPagination)
  const query = useAppSelector(selectEventCategoryQuery)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [ids, setIds] = useState<number[]>([])

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const [deleteMany] = useMutation(DELETE_MANY)

  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ces villes ?",
        isLoading: false,
      }),
    )
  }

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [{ name_fr: { contains: query, mode: QueryMode.Insensitive } }],
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_CITY, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: { name_fr: SortOrder.Asc },
      where: {
        ...queryName(),
      },
    },
  })

  const [deleteCity] = useMutation(DELETE_CITY)

  const columns = useMemo<MRT_ColumnDef<City>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "name_en",
        header: "Nom en En",
      },
      {
        accessorKey: "name_fr",
        header: "Nom en fr",
      },
      {
        accessorKey: "name_mg",
        header: "Nom en mg",
      },
      {
        accessorKey: "name_wl",
        header: "Nom en wl",
      },
      {
        accessorKey: "name_dj",
        header: "Nom en dj",
      },
      {
        accessorFn: (row) => row?.country?.name_fr,
        header: "Pays",
      },
      {
        accessorKey: "latitude",
        header: "Latitude",
      },
      {
        accessorKey: "longitude",
        header: "Longitude",
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationEventCategory(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteCity({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "La ville a été supprimée avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheCategory({
          action: "delete",
          cache,
          entryData: data?.deleteOneCity,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer cette ville ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setQueryEventCategory(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier une ville"))
    navigate(`/city/edit/${id}`)
  }

  if (loading && !query) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.cities || []}
      lableAddNew="Créer une nouvelle ville"
      rootLisName={"/city/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateCity._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={handleOpenDeleteDialog}
      handleEdit={handleEdit}
      handleSelected={handleSelected}
      handleDeleteGroup={handleDeleteGroup}
      selectedIds={ids}
    />
  )
}

export default ListCity
