import { styled, useTheme } from "@mui/material/styles"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  selectMenuStatus,
  selectMenuTitle,
  toogleOpen,
} from "../../../store/features/menu/menuSlice"
import { useMediaQuery } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const drawerWidth: number = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  "& .MuiToolbar-root": {
    minHeight: "50px",
  },
  backgroundColor: theme.palette.primary.main,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export default function AppBarBody() {
  const open = useAppSelector(selectMenuStatus)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const title = useAppSelector(selectMenuTitle)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <AppBar position="absolute" open={isMobile ? false : open} elevation={0}>
      <Toolbar
        sx={{
          pr: "24px",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(toogleOpen())}
          sx={{
            marginRight: "36px",
            ...(isMobile ? {} : open && { display: "none" }),
          }}
        >
          {isMobile && open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {title}
        </Typography>
        {/* <IconButton color="inherit">
          <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
      </Toolbar>
    </AppBar>
  )
}
