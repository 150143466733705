import { gql } from "@apollo/client"

export const COUNTRY_FRAGMENT = gql(`
  fragment CountryFragment on Country {
    id
    name_fr
    name_en
    name_mg
    name_wl
    name_dj
    alpha2
    alpha3
    currency
    latitude
    longitude
    eventPrice
    ticketCommission
    paimentProMinPrice
    currency
  }
`)
