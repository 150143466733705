import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import userReducer from "../features/user/userSlice"
import eventReducer from "../features/event/eventSlice"
import cityReducer from "../features/city/citySlice"
import menuReducer from "../features/menu/menuSlice"
import countryReducer from "../features/country/countrySlice"
import snackbarReducer from "../features/snackbar/snackbarSlice"
import placeReducer from "../features/place/placeSlice"
import customerReducer from "../features/customer/customerSlice"
import eventCategoryReducer from "../features/eventCategory/eventCategorySlice"
import placeCategoryReducer from "../features/placeCategory/placeCategorySlice"
import noticePlaceReducer from "../features/noticePlace/noticePlaceSlice"
import noticeEventReducer from "../features/noticeEvent/noticeEventSlice"
import alertReducer from "../features/alert/alertSlice"
import { setupListeners } from "@reduxjs/toolkit/query"

export const store = configureStore({
  reducer: {
    user: userReducer,
    event: eventReducer,
    city: cityReducer,
    menu: menuReducer,
    country: countryReducer,
    snackbar: snackbarReducer,
    place: placeReducer,
    customer: customerReducer,
    eventCategory: eventCategoryReducer,
    placeCategory: placeCategoryReducer,
    alert: alertReducer,
    noticePlace: noticePlaceReducer,
    noticeEvent: noticeEventReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["alert/setOpenAlert", "alert/setCloseAlert"],
        // Ignore these paths in the state
        ignoredPaths: ["alert.handleValid"],
      },
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

setupListeners(store.dispatch)
