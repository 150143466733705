import CryptoJS from "crypto-js"
import config from "../config/config"

export const encryptUserName = (userName: string) => {
  const encryptedUserName = CryptoJS.AES.encrypt(
    userName,
    config.encrypt.secret,
  ).toString()
  return encryptedUserName
}

export const encryptUserEmail = (email: string) => {
  const encryptedEmail = CryptoJS.AES.encrypt(
    email,
    config.encrypt.secret,
  ).toString()
  return encryptedEmail
}

export const encryptToken = (token: string) => {
  const encryptedToken = CryptoJS.AES.encrypt(
    token,
    config.encrypt.secret,
  ).toString()
  return encryptedToken
}

export const encryptedPermissions = (permissions: string[]) => {
  const permissionsString = JSON.stringify(permissions)
  const encryptedPermissions = CryptoJS.AES.encrypt(
    permissionsString,
    config.encrypt.secret,
  ).toString()
  return encryptedPermissions
}

export const decryptUserName = (userName: string) => {
  const decryptUserName = CryptoJS.AES.decrypt(userName, config.encrypt.secret)
  const originalUserName = decryptUserName.toString(CryptoJS.enc.Utf8)
  return originalUserName
}

export const decryptUserEmail = (email: string) => {
  const decryptEmail = CryptoJS.AES.decrypt(email, config.encrypt.secret)
  const originalEmail = decryptEmail.toString(CryptoJS.enc.Utf8)
  return originalEmail
}

export const decryptToken = (token: string) => {
  const decryptToken = CryptoJS.AES.decrypt(token, config.encrypt.secret)
  const originalToken = decryptToken.toString(CryptoJS.enc.Utf8)
  return originalToken
}

export const decryptPermissions = (permissions: string) => {
  const decryptPermissions = CryptoJS.AES.decrypt(
    permissions,
    config.encrypt.secret,
  )
  const originalPermissions = decryptPermissions.toString(CryptoJS.enc.Utf8)
  return JSON.parse(originalPermissions)
}
