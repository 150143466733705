import * as React from "react"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import UserChart from "./UserChart/UserChart"
import PlaceChart from "./PlaceChart/PlaceChart"
import EventChart from "./EventChart/EventChart"
import GainChart from "./GainChart/GainChart"
import ListAskPayment from "./ListAskPayment/ListAskPayment"

export default function LabTabs() {
  const [value, setValue] = React.useState("0")

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const style = {
    background: "dimgrey",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
  }
  return (
    <Box sx={{ maxWidth: "100%" }}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "white",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <TabList
            scrollButtons={"auto"}
            allowScrollButtonsMobile
            indicatorColor="primary"
            onChange={handleChange}
            TabIndicatorProps={{
              color: "#fff",
            }}
          >
            <Tab label="Benefices" value="0" />
            <Tab label="Évenements" value="1" />
            <Tab label="Lieux" value="2" />
            <Tab label="Utilisateurs" value="3" />
            <Tab label="Demande de versement" value="4" />
          </TabList>
        </Box>
        <TabPanel value="0" style={{ ...style }}>
          <GainChart />
        </TabPanel>
        <TabPanel value="1" style={style}>
          <EventChart />
        </TabPanel>
        <TabPanel value="2" style={style}>
          <PlaceChart />
        </TabPanel>
        <TabPanel value="3" style={{ ...style }}>
          <UserChart />
        </TabPanel>
        <TabPanel value="4" style={{ ...style }}>
          <ListAskPayment />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
