import { Button, Grid, Paper, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheCity } from "../../../caches/updateCacheCity"
import Loader from "../../Common/Loader/Loader"
import { InfinityListItem } from "../../../types/InfinityListItem"
import CustomListCountry from "../../Common/CustomListCountry/CustomListCountry"

const GET_CITY = graphql(`
  query City($where: CityWhereUniqueInput!) {
    city(where: $where) {
      id
      name_fr
      name_en
      name_mg
      name_wl
      name_dj
      latitude
      longitude
      country {
        id
        name_fr
      }
    }
  }
`)

const UPDATE_CITY = graphql(`
  mutation UpdateCity($data: CityUpdateInputType!) {
    updateCity(data: $data) {
      id
      name_fr
      name_en
      name_mg
      name_wl
      name_dj
      latitude
      longitude
      country {
        id
        name_fr
      }
    }
  }
`)

const CREATE_CITY = graphql(`
  mutation CreateCity($data: CityCreateInputType!) {
    createCity(data: $data) {
      id
      name_fr
      name_en
      name_mg
      name_wl
      name_dj
      latitude
      longitude
      country {
        id
        name_fr
      }
    }
  }
`)

export default function AddCity() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [country, setCountry] = useState({ label: "", value: "" })

  const [city, setCity] = useState({
    name_fr: "",
    name_en: "",
    name_mg: "",
    name_wl: "",
    name_dj: "",
    latitude: "",
    longitude: "",
  })

  const [create, { loading }] = useMutation(CREATE_CITY)
  const [update, { loading: loadingUpdate }] = useMutation(UPDATE_CITY)

  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const handleInputChange = (event: any) => {
    setCity({
      ...city,
      [event.target.name]: event.target.value,
    })
  }

  const [getCity, { loading: loadingInit }] = useLazyQuery(GET_CITY, {
    onCompleted(data) {
      const city = data?.city
      setCity({
        name_fr: city?.name_fr || "",
        name_en: city?.name_en || "",
        name_mg: city?.name_mg || "",
        name_wl: city?.name_wl || "",
        name_dj: city?.name_dj || "",
        latitude: city?.latitude?.toString() || "",
        longitude: city?.longitude?.toString() || "",
      })
      if (city?.country) {
        const newCountry: InfinityListItem = {
          value: city?.country.id.toString() || "",
          label: city?.country.name_fr || "",
        }
        setCountry(newCountry)
      }
    },
  })

  const handleCreate = () => {
    create({
      variables: {
        data: {
          ...city,
          latitude: city.latitude ? Number(city.latitude) : undefined,
          longitude: city.longitude ? Number(city.longitude) : undefined,
          countryId: Number(country.value),
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
      },
      onCompleted: () => {
        navigate("/city/list")
      },
      update: (cache, { data }) => {
        updateCacheCity({
          action: "add",
          cache,
          entryData: data?.createCity,
        })
      },
    })
  }

  const handleUpdate = () => {
    update({
      variables: {
        data: {
          ...city,
          latitude: city.latitude ? Number(city.latitude) : undefined,
          longitude: city.longitude ? Number(city.longitude) : undefined,
          id,
          countryId: Number(country.value),
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
      },
      onCompleted: () => {
        navigate("/city/list")
      },
      update: (cache, { data }) => {
        updateCacheCity({
          action: "update",
          cache,
          entryData: data?.updateCity,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (id) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  useEffect(() => {
    if (id) {
      getCity({
        variables: {
          where: {
            id,
          },
        },
      })
    }
  }, [id])

  if (loadingInit) return <Loader />

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Typography sx={{ textAlign: "center" }} variant="h1">
                  Ajouter une ville
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name_fr"
                  label="Nom en Fr"
                  fullWidth
                  variant="outlined"
                  value={city.name_fr}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name_en"
                  label="Nom en En"
                  fullWidth
                  variant="outlined"
                  value={city.name_en}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name_mg"
                  label="Nom en MG"
                  fullWidth
                  variant="outlined"
                  value={city.name_mg}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name_dj"
                  label="Nom en DJ"
                  fullWidth
                  variant="outlined"
                  value={city.name_dj}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name_wl"
                  label="Nom en WL"
                  fullWidth
                  variant="outlined"
                  value={city.name_wl}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <CustomListCountry
                  countryDefault={country}
                  getCountry={getCountry}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="latitude"
                  label="Latitude"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={city.latitude}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="longitude"
                  label="Longitude"
                  fullWidth
                  type="number"
                  variant="outlined"
                  value={city.longitude}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || loadingUpdate}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
