import { Box, Grid, Typography } from "@mui/material"
import PieChart from "../PieChart/PieChart"
import { useEffect, useState } from "react"
import CustomListEvent from "../../Common/CustomListEvent/CustomListEvent"
import CustomListCategoryEvents from "../../Common/CustomListCategoryEvents/CustomListCategoryEvents"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { graphql } from "../../../gql"
import { useLazyQuery } from "@apollo/client"

const GLOBALE = graphql(`
  query GetEventStatisticByCategory($categoryId: Int!) {
    getEventStatisticByCategory(categoryId: $categoryId) {
      value
      label
      color
    }
  }
`)

const PARTICLE = graphql(`
  query GetEventStatisticById($eventId: Int!) {
    getEventStatisticById(eventId: $eventId) {
      value
      label
      color
    }
  }
`)

function EventChart() {
  const [event, setEvent] = useState<InfinityListItem>({ label: "", value: "" })
  const [categories, setCategories] = useState<InfinityListItem>({
    label: "",
    value: "",
  })

  const styleText = { color: "black" }

  const [getGlobal, { data: dataGlobal }] = useLazyQuery(GLOBALE)
  const [getParticle, { data: dataParticle }] = useLazyQuery(PARTICLE)

  const grid = {
    lg: 5,
    p: 2,
    mb: 2,
    bgcolor: "white",
    borderRadius: 2,
    xs: 11,
    md: 11,
    sm: 11,
  }

  useEffect(() => {
    if (event.value) {
      getParticle({
        variables: {
          eventId: +event.value,
        },
      })
    }
  }, [event])

  useEffect(() => {
    if (categories.value) {
      getGlobal({
        variables: {
          categoryId: +categories.value,
        },
      })
    }
  }, [categories])

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{ justifyContent: "space-evenly" }}
    >
      <Grid item {...grid}>
        <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
          <Box width={"35%"}>
            <Typography sx={styleText} variant="h5">
              Vue par évenement:
            </Typography>
          </Box>
          <Box width={"65%"}>
            <CustomListEvent
              eventDefault={event}
              getEvent={setEvent}
              label=""
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <PieChart
            data={dataParticle?.getEventStatisticById || []}
            margin={{ bottom: 50, right: 50, left: 50 }}
          />
        </Box>
      </Grid>
      <Grid item {...grid}>
        <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
          <Box width={"35%"}>
            <Typography sx={styleText} variant="h5">
              Vue par catégorie:
            </Typography>
          </Box>
          <Box width={"65%"}>
            <CustomListCategoryEvents
              categoryeventsDefault={categories}
              getCategoryEvents={setCategories}
              label=""
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <PieChart
            data={dataGlobal?.getEventStatisticByCategory || []}
            margin={{ right: 40, left: 40 }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default EventChart
