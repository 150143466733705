import React from "react"
import Switch from "react-switch"

interface Props {
  checked: boolean
  id?: string
  handleChange: (e: any) => void
}

const SwitchButton = ({ checked, handleChange, id }: Props) => {
  return <Switch onChange={handleChange} checked={checked} id={id}/>
}

export default SwitchButton
