import { useCallback, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useMutation, useQuery } from "@apollo/client"
import {
  selectNoticePlacePagination,
  setQueryNoticePlace,
  setPaginationNoticePlace,
  selectNoticePlaceQuery,
} from "../../../store/features/noticePlace/noticePlaceSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { SortOrder, NoticePlace, QueryMode } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import SwitchButton from "../../Common/SwitchButton/SwitchButton"
import { updateCacheNoticePlace } from "../../../caches/updateCacheNoticePlace"

export const LIST_NOTICEPLACES = graphql(`
  query NoticePlaces(
    $where: NoticePlaceWhereInput
    $aggregateNoticePlaceWhere2: NoticePlaceWhereInput
    $orderBy: [NoticePlaceOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    noticePlaces(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      note
      isPublish
      description
      createdAt
      creator {
        id
        name
        lastName
      }
      place {
        id
        name
      }
    }
    aggregateNoticePlace(where: $aggregateNoticePlaceWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_NOTICEPLACE = graphql(`
  mutation DeleteOneNoticePlace($where: NoticePlaceWhereUniqueInput!) {
    deleteOneNoticePlace(where: $where) {
      id
    }
  }
`)

export const UPDATE_NOTICEPLACE = graphql(`
  mutation UpdateOneNoticePlace(
    $data: NoticePlaceUpdateInput!
    $where: NoticePlaceWhereUniqueInput!
  ) {
    updateOneNoticePlace(data: $data, where: $where) {
      id
      note
      isPublish
      description
      createdAt
      creator {
        id
        name
        lastName
      }
      place {
        id
        name
      }
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyNoticePlace($where: NoticePlaceWhereInput) {
    deleteManyNoticePlace(where: $where) {
      count
    }
  }
`)

const ListNoticePlace = () => {
  const { page, size } = useAppSelector(selectNoticePlacePagination)
  const query = useAppSelector(selectNoticePlaceQuery)
  const dispatch = useAppDispatch()
  const [ids, setIds] = useState<number[]>([])

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const [deleteMany] = useMutation(DELETE_MANY)

  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ces avis ?",
        isLoading: false,
      }),
    )
  }

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            {
              place: {
                is: {
                  name: { contains: query, mode: QueryMode.Insensitive },
                },
              },
            },
            {
              creator: {
                is: {
                  OR: [
                    { name: { contains: query, mode: QueryMode.Insensitive } },
                    {
                      lastName: {
                        contains: query,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
            },
          ],
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_NOTICEPLACES, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: [{ createdAt: SortOrder.Desc }, { placeId: SortOrder.Desc }],
      where: {
        ...queryName(),
      },
    },
  })

  const [deleteNoticePlace] = useMutation(DELETE_NOTICEPLACE)
  const [updateNoticePlace] = useMutation(UPDATE_NOTICEPLACE)

  const handleTooglePublish = (id: number, isPublish: boolean) => {
    dispatch(setLoadingDelete(true))
    updateNoticePlace({
      variables: {
        where: {
          id,
        },
        data: {
          isPublish: {
            set: !isPublish,
          },
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "La modification a été faite avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheNoticePlace({
          action: "update",
          cache,
          entryData: data?.updateOneNoticePlace,
        })
      },
    })
  }

  const handleShowAlertPublish = (id: number, isPublish: boolean) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleTooglePublish(id, isPublish),
        message:
          "Êtes-vous vraiment sûr de vouloir modifier la statut de publication de cet avis ?",
        isLoading: false,
      }),
    )
  }

  const columns = useMemo<MRT_ColumnDef<NoticePlace>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isPublish)}
              handleChange={() =>
                handleShowAlertPublish(row.id, Boolean(row.isPublish))
              }
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de publication",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorKey: "note",
        header: "Avis",
      },
      {
        accessorKey: "description",
        header: "Commentaire",
      },
      {
        accessorFn: (row) => row.creator.id,
        header: "ID de créateur",
      },
      {
        accessorFn: (row) => row.creator.lastName || row?.creator?.name,
        header: "Nom de Créateur",
      },
      {
        accessorFn: (row) => row.place.id,
        header: "ID de Lieu",
      },
      {
        accessorFn: (row) => row.place.name,
        header: "Nom de Lieu",
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationNoticePlace(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteNoticePlace({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "L'avis a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheNoticePlace({
          action: "delete",
          cache,
          entryData: data?.deleteOneNoticePlace,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer cet avis ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setQueryNoticePlace(value))

  if (loading && !query) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.noticePlaces || []}
      lableAddNew=""
      rootLisName={""}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateNoticePlace._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={handleOpenDeleteDialog}
      handleSelected={handleSelected}
      handleDeleteGroup={handleDeleteGroup}
      selectedIds={ids}
    />
  )
}

export default ListNoticePlace
