import { Box, CircularProgress } from "@mui/material"

interface Props {
  size?: number
}

const Loader = ({ size = 30 }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={size} />
    </Box>
  )
}
export default Loader
