import { styled, useTheme } from "@mui/material/styles"
import MuiDrawer from "@mui/material/Drawer"
import Divider from "@mui/material/Divider"
import { List, useMediaQuery } from "@mui/material"
import ListMain from "./ListMain/ListMain"
import { useAppSelector } from "../../../store/app/hooks"
import { selectMenuStatus } from "../../../store/features/menu/menuSlice"
import DrawerHeader from "./DrawerHeader/DrawerHeader"

const drawerWidth: number = 250

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
      [theme.breakpoints.down("md")]: {
        width: 0,
      },
    }),
  },
}))

export default function DrawerBar() {
  const open = useAppSelector(selectMenuStatus)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const height = window.innerHeight
  return (
    <Drawer
      variant={(isMobile && open) || !isMobile ? "permanent" : "persistent"}
      sx={isMobile ? { position: "absolute", top: "50px" } : {}}
      open={open}
      PaperProps={{
        sx: {
          overflowY: "auto",
          height: `${height}px`
        },
      }}
    >
      {!isMobile && <DrawerHeader />}
      <Divider />
      <List component="nav">
        <ListMain />
      </List>
    </Drawer>
  )
}
