import "./App.css"
import { useEffect } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import AddCity from "./components/City/AddCity/AddCity"
import ListEvent from "./components/Event/ListEvent/ListEvent"
import ListCity from "./components/City/ListCity/ListCity"
import Dashboard from "./components/Dashboard/Dashboard"
import AddEvent from "./components/Event/AddEvent/AddEvent"
import Layout from "./components/Layout/Layout"
import Login from "./components/Login/Login"
import ListEventCategory from "./components/EventCategory/ListEventCategory/ListEventCategory"
import AddPlace from "./components/Place/AddPlace/AddPlace"
import ListPlace from "./components/Place/ListPlace/ListPlace"
import ListUser from "./components/User/ListUser/ListUser"
import AddEventCategory from "./components/EventCategory/AddEventCategory/AddEventCategory"
import AddCountry from "./components/Country/AddCountry/AddCountry"
import ListCountry from "./components/Country/ListCountry/ListCountry"
import AddAdmin from "./components/Admin/AddAdmin/AddAdmin"
import ListAdmin from "./components/Admin/ListAdmin/ListAdmin"
import { useAppDispatch } from "./store/app/hooks"
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute"
import { useMediaQuery, useTheme } from "@mui/material"
import { setOpen } from "./store/features/menu/menuSlice"
import AddPlaceCategory from "./components/PlaceCategory/AddPlaceCategory/AddPlaceCategory"
import ListPlaceCategory from "./components/PlaceCategory/ListPlaceCategory/ListPlaceCategory"
import ListNoticePlace from "./components/Place/ListNotice/ListNotice"
import ListNoticeEvent from "./components/Event/ListNotice/ListNotice"

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <ProtectedRoute isProtected={false}>
        <Login />
      </ProtectedRoute>
    ),
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "event/add",
        element: <AddEvent />,
      },
      {
        path: "event/edit/:id",
        element: <AddEvent />,
      },
      {
        path: "event/list",
        element: <ListEvent />,
      },
      {
        path: "event-notice/list",
        element: <ListNoticeEvent />,
      },
      {
        path: "event-category/add",
        element: <AddEventCategory />,
      },
      {
        path: "place-category/add",
        element: <AddPlaceCategory />,
      },
      {
        path: "event-category/edit/:id",
        element: <AddEventCategory />,
      },
      {
        path: "place-category/edit/:id",
        element: <AddPlaceCategory />,
      },
      {
        path: "event-category/list",
        element: <ListEventCategory />,
      },
      {
        path: "place-category/list",
        element: <ListPlaceCategory />,
      },
      {
        path: "city/add",
        element: <AddCity />,
      },
      {
        path: "city/edit/:id",
        element: <AddCity />,
      },
      {
        path: "city/list",
        element: <ListCity />,
      },

      {
        path: "country/add",
        element: <AddCountry />,
      },
      {
        path: "country/edit/:id",
        element: <AddCountry />,
      },
      {
        path: "country/list",
        element: <ListCountry />,
      },
      {
        path: "place/add",
        element: <AddPlace />,
      },
      {
        path: "place/edit/:id",
        element: <AddPlace />,
      },
      {
        path: "place/list",
        element: <ListPlace />,
      },
      {
        path: "place-notice/list",
        element: <ListNoticePlace />,
      },
      {
        path: "user/list",
        element: <ListUser />,
      },
      {
        path: "admin/add",
        element: <AddAdmin />,
      },
      {
        path: "admin/edit/:id",
        element: <AddAdmin />,
      },
      {
        path: "admin/list",
        element: <ListAdmin />,
      },
    ],
  },
])

function Router() {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  useEffect(() => {
    dispatch(setOpen(!isMobile))
  }, [])

  return <RouterProvider router={router} />
}

export default Router
