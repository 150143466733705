import { gql } from "@apollo/client"

export const ADMIN_FRAGMENT = gql(`
  fragment AdminFragment on User {
    id
    phoneNumber
    email
    role
    name
    lastName
    profile {
      url
      id
    }
    Country {
      id
      name_fr
    }
  }
`)
