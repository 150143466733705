import { Button, Grid, Paper, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import CustomUploadFile, {
  MinTypeFile,
} from "../../Common/CustomUploadFile/CustomUploadFile"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { graphql } from "../../../gql"
import CustomListCity from "../../Common/CustomListCity/CustomListCity"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { useLazyQuery, useMutation } from "@apollo/client"
import { NewFileInput } from "../../../gql/graphql"
import { updateCachePlace } from "../../../caches/updateCachePlace"
import { uploadFile } from "../../../utils/uploadFile"
import Loader from "../../Common/Loader/Loader"
import CustomListCategoryPlace from "../../Common/CustomListCategoryPlace/CustomListCategoryPlace"

const GET_PLACE = graphql(`
  query Place($where: PlaceWhereUniqueInput!) {
    place(where: $where) {
      id
      latitude
      longitude
      descriptions
      isPublish
      name
      categories {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const UPDATE_PLACE = graphql(`
  mutation UpdatePlace(
    $data: PlaceUpdateInputType!
    $coverImage: NewFileInput
  ) {
    updatePlace(data: $data, coverImage: $coverImage) {
      id
      latitude
      longitude
      isPublish
      descriptions
      name
      categories {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const CREATE_PLACE = graphql(`
  mutation CreatePlace(
    $data: PlaceCreateInputType!
    $coverImage: NewFileInput
  ) {
    createPlace(data: $data, coverImage: $coverImage) {
      id
      latitude
      longitude
      isPublish
      descriptions
      name
      categories {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

export default function AddPlace() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [city, setCity] = useState({ label: "", value: "" })
  const [file, setCoverImage] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [create, { loading }] = useMutation(CREATE_PLACE)
  const [update, { loading: loadingUpdate }] = useMutation(UPDATE_PLACE)
  const [categories, setCategories] = useState<InfinityListItem[]>([])
  const [defaulPhoto, setDefaulPhoto] = useState<MinTypeFile | undefined>(
    undefined,
  )

  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const onFileUploadCoverImage = (file: File) => {
    setCoverImage(file)
  }

  const onClearUploadCoverImage = () => setCoverImage(null)

  const [place, setPlace] = useState({
    name: "",
    latitude: "",
    longitude: "",
    descriptions: "",
  })

  const [getPlace, { loading: loadingInit }] = useLazyQuery(GET_PLACE, {
    onCompleted(data) {
      const place = data?.place
      setPlace({
        name: place?.name || "",
        latitude: place?.latitude?.toString() || "",
        longitude: place?.longitude?.toString() || "",
        descriptions: place?.descriptions?.toString() || "",
      })
      if (place?.coverImage) {
        const { url, id } = place?.coverImage
        setDefaulPhoto({ url, id })
      }
      if (place?.city) {
        const newCity: InfinityListItem = {
          value: place?.city.id.toString() || "",
          label: place?.city.name_fr || "",
        }
        setCity(newCity)
      }
      if (place?.categories?.length) {
        const newCategory: InfinityListItem[] = place?.categories.map((el) => ({
          value: el?.id.toString() || "",
          label: el?.name || "",
        }))
        setCategories(newCategory)
      }
    },
  })

  const handleInputChange = (event: any) => {
    setPlace({
      ...place,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreate = (coverImage?: NewFileInput) => {
    create({
      variables: {
        data: {
          name: place.name,
          descriptions: place.descriptions,
          cityId: city?.value ? +city.value : undefined,
          latitude: place.latitude ? parseFloat(place.latitude) : undefined,
          longitude: place.longitude ? parseFloat(place.longitude) : undefined,
          categories: categories.map((el) => +el.value),
        },
        coverImage,
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/place/list")
      },
      update: (cache, { data }) => {
        updateCachePlace({
          action: "add",
          cache,
          entryData: data?.createPlace,
        })
      },
    })
  }

  const handleUpdate = (coverImage?: NewFileInput) => {
    update({
      variables: {
        data: {
          name: place.name,
          descriptions: place.descriptions,
          cityId: city?.value ? +city.value : undefined,
          latitude: place.latitude ? parseFloat(place.latitude) : undefined,
          longitude: place.longitude ? parseFloat(place.longitude) : undefined,
          categories: categories.map((el) => +el.value),
          id,
        },
        coverImage,
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/place/list")
      },
      update: (cache, { data }) => {
        updateCachePlace({
          action: "update",
          cache,
          entryData: data?.updatePlace,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (!categories.length) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez sélectionner au moins une catégorie de lieu.",
          status: "error",
        }),
      )
      return
    }
    if (file) {
      setIsLoading(true)
      uploadFile({
        cbError: ({ message }) => {
          setOpenSnackbar({
            message,
          })
          setIsLoading(false)
        },
        cbOk: (file) => {
          if (id) {
            handleUpdate(file)
          } else {
            handleCreate(file)
          }
        },
        file,
      })
    } else {
      if (id) {
        handleUpdate()
      } else {
        handleCreate()
      }
    }
  }

  const getCity = (city: InfinityListItem) => {
    setCity(city)
  }

  useEffect(() => {
    if (id) {
      getPlace({
        variables: {
          where: {
            id,
          },
        },
      })
    }
  }, [id])

  if (loadingInit) return <Loader />

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Typography sx={{ textAlign: "center" }} variant="h1">
                  Ajouter un lieu
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name"
                  label="Nom"
                  fullWidth
                  variant="outlined"
                  value={place.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomListCategoryPlace
                  categoryplaceDefault={categories}
                  getCategoryPlace={setCategories}
                  multiple
                  required={false}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomListCity
                  cityDefault={city}
                  getCity={getCity}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="latitude"
                  label="Latitude"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={place.latitude}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="longitude"
                  label="Longitude"
                  fullWidth
                  type="number"
                  variant="outlined"
                  value={place.longitude}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomUploadFile
                  onClearUpload={onClearUploadCoverImage}
                  onFileUpload={onFileUploadCoverImage}
                  label="Photo de couveture"
                  id="photo-de-couveture"
                  defaultUrl={defaulPhoto}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  multiline
                  minRows={3}
                  label="Déscription"
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  value={place.descriptions}
                  name="descriptions"
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || isLoading || loadingUpdate}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
