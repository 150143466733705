// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"
import { MessagePayload, getMessaging, onMessage } from "firebase/messaging"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPkfKZN9g5MM2fhsF4LkFsLA4oD9oqPgY",
  authDomain: "le-hub-414014.firebaseapp.com",
  projectId: "le-hub-414014",
  storageBucket: "le-hub-414014.appspot.com",
  messagingSenderId: "809861390112",
  appId: "1:809861390112:web:bcb13e655181f11b748145",
  measurementId: "G-2DVP4LMSEF"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const storage = getStorage(app)

// Messaging service
export const messaging = getMessaging(app)
export default storage

export const onForegroundMessage: () => Promise<MessagePayload> = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)))
