/* eslint-disable no-empty-pattern */
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

interface AlerState {
  open?: boolean
  message?: string | string[]
  handleValid: () => void
  isLoading: boolean
}

const initialState: AlerState = {
  open: false,
  isLoading: false,
  message: "",
  handleValid: () => {},
}

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setLoadingDelete: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setOpenAlert: (state, action: PayloadAction<AlerState | undefined>) => {
      return {
        ...state,
        ...action?.payload,
        open: true,
      }
    },
    setCloseAlert: (state) => {
      state.open = false
      state.handleValid = () => {}
    },
  },
})

export const { setOpenAlert, setCloseAlert, setLoadingDelete } =
  alertSlice.actions
export const selectAlertStatus = (state: RootState) => state.alert

export default alertSlice.reducer
