import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface PlaceCategoryState {
  pagination: Pagination
  query: string
}

const initialState: PlaceCategoryState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const placeCategorySlice = createSlice({
  name: "placeCategory",
  initialState,
  reducers: {
    setPaginationPlaceCategory: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setQueryPlaceCategory: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})

export const selectPlaceCategoryPagination = (state: RootState) =>
  state.placeCategory.pagination
export const selectPlaceCategoryQuery = (state: RootState) =>
  state.placeCategory.query
export const { setPaginationPlaceCategory, setQueryPlaceCategory } =
  placeCategorySlice.actions
export default placeCategorySlice.reducer
