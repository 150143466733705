import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"

const LIST_PLACES = graphql(`
  query ListPlaces(
    $where: PlaceWhereInput
    $orderBy: [PlaceOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    places(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name
      longitude
      latitude
    }
  }
`)

interface Props {
  getPlace: (place: any) => void
  placeDefault: InfinityListItem | InfinityListItem[]
  label?: string
  required?: boolean
  multiple?: boolean
}
const CustomListPlace = ({
  getPlace,
  placeDefault,
  label = "Lieu",
  required = true,
  multiple = false,
}: Props) => {
  const [place, setPlace] = useState(placeDefault)

  const [queryPlace, setQueryPlace] = useState("")
  const [openPlace, setOpenPlace] = useState(false)
  const size = 500

  const queryName = useCallback(() => {
    const OrClause = {
      OR: [{ name: { contains: queryPlace, mode: QueryMode.Insensitive } }],
    }
    return queryPlace ? OrClause : {}
  }, [queryPlace])

  const { loading, data, fetchMore } = useQuery(LIST_PLACES, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name: SortOrder.Asc },
      where: {
        isPublish: {
          equals: true,
        },
        ...(queryPlace && {
          ...queryName(),
        }),
      },
    },
  })

  const handleSearchCategory = (query: string) => {
    setQueryPlace(query)
  }

  const handleCloseCategory = () => {
    setOpenPlace(false)
  }
  const handleOpenPlace = async () => {
    setOpenPlace(true)
  }

  const onEndReachedCategory = () => {
    fetchMore({
      variables: {
        skip: data?.places.length,
      },
    })
  }

  useEffect(() => {
    if (place && placeDefault) {
      if (!Array.isArray(place) && !Array.isArray(placeDefault)) {
        if (!place?.value && placeDefault?.value) {
          setPlace(placeDefault)
        }
      } else {
        setPlace(placeDefault)
      }
    }
  }, [place, placeDefault])

  const dataPlace = data?.places

  const places =
    loading || !dataPlace
      ? []
      : dataPlace.map((el) => ({
          label: el.name,
          value: el.id,
          extraData: { latitude: el.latitude, longitude: el.longitude },
        }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseCategory}
      handleOpen={handleOpenPlace}
      open={openPlace}
      defaultValue={placeDefault}
      keyName="label"
      label={label}
      onSearch={handleSearchCategory}
      options={openPlace ? places : []}
      loading={loading}
      multiple={multiple}
      onChange={(value) => {
        if (value) {
          setPlace(value)
          getPlace(value)
        }
      }}
      query={queryPlace}
      value={place}
      onEndReached={onEndReachedCategory}
      required={required}
    />
  )
}

export default CustomListPlace
