import { gql } from "@apollo/client"

export const NOTICEEVENT_FRAGMENT = gql(`
  fragment NoticeEventFragment on NoticeEvent {
    id
    note
    isPublish
    description
    createdAt
    creator {
      id
      name
      lastName
    }
    event {
      id
      title
    }
  }
`)
