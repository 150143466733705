import { Outlet } from "react-router-dom"
import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import DrawerBar from "./Drawer/Drawer"
import AppBarBody from "./AppBar/AppBar"
import { useMediaQuery, useTheme } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../store/app/hooks"
import {
  selectMenuStatus,
  toogleOpen,
} from "../../store/features/menu/menuSlice"

export default function Layout() {
  const div = React.useRef<any>(null)
  const theme = useTheme()
  const open = useAppSelector(selectMenuStatus)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const height = window.innerHeight
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(toogleOpen())
  }

  const handleClickOutside = (event: any) => {
    if (
      div &&
      div?.current &&
      div?.current?.contains(event.target) &&
      isMobile &&
      open
    ) {
      event.stopPropagation()
      handleClose()
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarBody />
      <DrawerBar />
      <Box
        component="main"
        sx={{
          backgroundColor: "white",
          flexGrow: 1,
          maxHeight: "100vh",
          overflow: "auto",
        }}
        onClick={handleClickOutside}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
          <div id="detail">
            <Outlet />
          </div>
        </Container>
        {isMobile && open && (
          <div
            ref={div}
            className={isMobile && open ? "bodyDrawerACtive" : "bodyDrawer"}
            style={{ height: `${height}px` }}
          />
        )}
      </Box>
    </Box>
  )
}
