import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  StorageError,
} from "firebase/storage"
import imageCompression from "browser-image-compression"
import storage from "../firebase/firebase"
import { NewFileInput } from "../gql/graphql"
import config from "../config/config"

export const createFileFromBlob = (blob: Blob, filename: string) => {
  return new File([blob], filename, { type: blob.type })
}

const compressImage = async (file: File) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }
  const compressedFile = await imageCompression(file, options)
  return compressedFile
}

const handleUploadFileToFirebase = ({
  file,
  getPrecent,
  cbError,
  cbOk,
}: {
  file: File
  getPrecent?: (value: number) => void
  cbError: (err: StorageError) => void
  cbOk: (file: NewFileInput) => void
}) => {
  if (!file) {
    alert("Veuiller choisir un fichier à télécharger")
    return
  }
  if (file.size > 1 * 1024 * 1024 && file.type.includes("image")) {
    alert("La taille de fichier est doit être inferieur à 1MB")
    return
  }
  let path = "images"

  if (file.type.includes("image")) {
    path = "images/"
  } else if (file.type.includes("audio")) {
    path = "audios/"
  } else {
    path = "videos/"
  }

  const prePath = config.isOnDev ? "/test-" : "/"

  const storageRef = ref(storage, `${prePath}${path}${file.name}`)
  const uploadTask = uploadBytesResumable(storageRef, file)
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
      )
      // update progress
      getPrecent && getPrecent(percent)
    },
    cbError,
    () => {
      // download url
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        cbOk({
          name: file.name,
          type: file.type,
          size: file.size,
          url,
        })
      })
    },
  )
}

export const uploadFile = ({
  file,
  getPrecent,
  cbError,
  cbOk,
}: {
  file: File
  getPrecent?: (value: number) => void
  cbError: (err: StorageError) => void
  cbOk: (file: NewFileInput) => void
}) => {
  if (file.type.includes("image")) {
    compressImage(file)
      .then((compressedFile) => {
        handleUploadFileToFirebase({
          cbError,
          cbOk,
          file: compressedFile,
          getPrecent,
        })
      })
      .catch((err) => {
        cbError(err)
      })
  } else {
    handleUploadFileToFirebase({
      cbError,
      cbOk,
      file,
      getPrecent,
    })
  }
}
