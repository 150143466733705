import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"
import { User } from "../../../gql/graphql"

export interface UserState {
  user: User | null
  token: string | null
  pagination: Pagination
  query: string
}

const initialState: UserState = {
  token: null,
  user: null,
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (
      state,
      action: PayloadAction<{ user: any; token: string }>,
    ) => {
      state.token = action.payload.token
      state.user = action.payload.user
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    setUserPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
    logout: (state) => {
      state.token = null
      state.user = null
    },
  },
})
export const selectUserPagination = (state: RootState) => state.user.pagination
export const selectUser = (state: RootState) => state.user.user
export const selectUserQuery = (state: RootState) => state.user.query
export const {
  setToken,
  setUser,
  setUserPagination,
  setUserData,
  setQuery,
  logout,
} = userSlice.actions
export default userSlice.reducer
