import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface PlaceState {
  pagination: Pagination
  query: string
}

const initialState: PlaceState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const placeSlice = createSlice({
  name: "place",
  initialState,
  reducers: {
    setPaginationPlace: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setQueryPlace: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})

export const selectPlacePagination = (state: RootState) =>
  state.place.pagination
export const selectPlaceQuery = (state: RootState) => state.place.query
export const { setPaginationPlace, setQueryPlace } = placeSlice.actions
export default placeSlice.reducer
