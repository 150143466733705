import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./store/app/store"
import App from "./App"
import "./index.css"
import client from "./apollo/client"
import { ApolloProvider } from "@apollo/client"



ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client()}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
)
