import { Box, Grid, Typography } from "@mui/material"
import PieChart from "../PieChart/PieChart"
import { useEffect, useState } from "react"
import Loader from "../../Common/Loader/Loader"
import CustomListUser from "../../Common/CustomListUser/CustomListUser"
import { graphql } from "../../../gql"
import { useLazyQuery } from "@apollo/client"

const GLOBALE = graphql(`
  query GetUserStatisticGlobal {
    getUserStatisticGlobal {
      label
      value
      color
    }
  }
`)

const PARTICLE = graphql(`
  query GetUserStatisticByUser($userId: Int!) {
    getUserStatisticById(userId: $userId) {
      color
      label
      value
    }
  }
`)

function UserChart() {
  const [user, setUser] = useState({ label: "", value: "" })
  const styleText = { color: "black" }
  const [getGlobal, { data: dataGlobal }] = useLazyQuery(GLOBALE)
  const [getParticle, { data: dataParticle }] = useLazyQuery(PARTICLE)

  const grid = {
    lg: 5,
    xs: 11,
    md: 11,
    sm: 11,
    p: 2,
    mb: 2,
    bgcolor: "white",
    borderRadius: 2,
  }

  useEffect(() => {
    if (user.value) {
      getParticle({
        variables: {
          userId: +user.value,
        },
      })
    }
  }, [user])

  useEffect(() => {
    getGlobal()
  }, [])

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{ justifyContent: "space-evenly" }}
    >
      <Grid item {...grid}>
        <Box>
          <Typography sx={styleText} variant="h5">
            Vue globale:
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <PieChart
            data={dataGlobal?.getUserStatisticGlobal || []}
            margin={{ right: 50, left: 50, bottom: 60 }}
          />
        </Box>
      </Grid>
      <Grid item {...grid}>
        <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
          <Box width={"35%"}>
            <Typography sx={styleText} variant="h5">
              Vue par utilisateur:
            </Typography>
          </Box>
          <Box width={"65%"}>
            <CustomListUser getUser={setUser} label="" userDefault={user} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <PieChart
            data={dataParticle?.getUserStatisticById || []}
            margin={{ bottom: 50, right: 50, left: 50 }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default UserChart
