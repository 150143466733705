import { gql } from "@apollo/client"

export const NOTICEPLACE_FRAGMENT = gql(`
  fragment NoticePlaceFragment on NoticePlace {
    id
    note
    isPublish
    description
    createdAt
    creator {
      id
      name
      lastName
    }
    place {
      id
      name
    }
  }
`)
