export const getValue = (value: any) => {
  return value ? value : "Ne pas préciser"
}

export const getStringEmpty = (str: string | null | undefined) =>
  !str ? "" : str

export const spliceString = (text: string, length?: number) => {
  if (text) {
    if (length) {
      return `${text.slice(0, length)}...`
    }
    return text
  }
  return ""
}

export const capitalizeFirstLetter = (str: string) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};