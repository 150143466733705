import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import CustomUploadFile, {
  MinTypeFile,
} from "../../Common/CustomUploadFile/CustomUploadFile"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import CustomListCategoryEvents from "../../Common/CustomListCategoryEvents/CustomListCategoryEvents"
import CustomListCity from "../../Common/CustomListCity/CustomListCity"
import CustomListPlace from "../../Common/CustomListPlace/CustomListPlace"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import Loader from "../../Common/Loader/Loader"
import { uploadFile } from "../../../utils/uploadFile"
import { NewFileInput, TicketPricingType } from "../../../gql/graphql"
import { updateCacheEvent } from "../../../caches/updateCacheEvent"
import TicketPricingDialog from "./TicketPricingDialog/TicketPricingDialog"

const GET_EVENT = graphql(`
  query Event($where: EventWhereUniqueInput!) {
    event(where: $where) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      isFree
      ticketPricings {
        id
        isPublic
        name
        nbAvailible
        price
        descriptions
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const UPDATE_EVENT = graphql(`
  mutation UpdateEvent(
    $data: EventUpdateInputType!
    $coverImage: NewFileInput
  ) {
    updateEvent(data: $data, coverImage: $coverImage) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      isFree
      ticketPricings {
        id
        isPublic
        name
        nbAvailible
        price
        descriptions
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const CREATE_EVENT = graphql(`
  mutation CreateEvent(
    $data: EventCreateInputType!
    $coverImage: NewFileInput
  ) {
    createEvent(data: $data, coverImage: $coverImage) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      isFree
      ticketPricings {
        id
        isPublic
        name
        nbAvailible
        price
        descriptions
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

export default function AddEvent() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [city, setCity] = useState<InfinityListItem>({ label: "", value: "" })
  const [place, setPlace] = useState<InfinityListItem>({ label: "", value: "" })
  const [pricing, setPricing] = useState<string>("free")
  const [openPrice, setOpenPrice] = useState<boolean>(false)
  const [categories, setCategories] = useState<InfinityListItem>({
    label: "",
    value: "",
  })
  const [file, setCoverImage] = useState<File | null>(null)
  const [ticketPricings, setTicketPricings] = useState<TicketPricingType[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [create, { loading }] = useMutation(CREATE_EVENT)
  const [update, { loading: loadingUpdate }] = useMutation(UPDATE_EVENT)

  const [defaulPhoto, setDefaulPhoto] = useState<MinTypeFile | undefined>(
    undefined,
  )

  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const [event, setEvent] = useState({
    title: "",
    numberPeopleMax: "",
    descriptions: "",
    endedDate: "",
    startedDate: "",
    startedTime: "",
    endedTime: "",
    latitude: "",
    longitude: "",
  })

  const handleInputChange = (e: any) => {
    setEvent({
      ...event,
      [e.target.name]: e.target.value,
    })
  }

  const [getEvent, { loading: loadingInit }] = useLazyQuery(GET_EVENT, {
    onCompleted(data) {
      const event = data?.event
      setEvent({
        title: event?.title || "",
        numberPeopleMax: event?.numberPeopleMax?.toString() || "",
        descriptions: event?.descriptions || "",
        endedDate: formatInitialDateTime(event?.endedDate).formattedDate || "",
        startedDate:
          formatInitialDateTime(event?.startedDate).formattedDate || "",
        startedTime:
          formatInitialDateTime(event?.startedTime).formattedTime || "",
        endedTime: formatInitialDateTime(event?.endedTime).formattedTime || "",
        latitude: event?.latitude?.toString() || "",
        longitude: event?.longitude?.toString() || "",
      })

      setPricing(event?.isFree ? "free" : "paid")

      if (event?.coverImage) {
        const { url, id } = event?.coverImage
        setDefaulPhoto({ url, id })
      }

      if (event?.ticketPricings) {
        setTicketPricings([
          ...event?.ticketPricings.map((el) => ({
            ...el,
            __typename: "TicketPricingType",
          })),
        ])
      }

      if (event?.city) {
        const newCity: InfinityListItem = {
          value: event?.city.id.toString() || "",
          label: event?.city.name_fr || "",
        }
        setCity(newCity)
      }

      if (event?.place) {
        const newPlace: InfinityListItem = {
          value: event?.place.id.toString() || "",
          label: event?.place.name || "",
        }
        setPlace(newPlace)
      }
      if (event?.categories?.length) {
        const newCategory: InfinityListItem = {
          value: event?.categories[0].id.toString() || "",
          label: event?.categories[0].name || "",
        }
        setCategories(newCategory)
      }
    },
  })

  const formatedDate = (date: string, horaire: string) => {
    return new Date(`${date}T${horaire}:00`)
  }

  const onCoverImageUpload = (coverimage: any) => {
    setCoverImage(coverimage)
  }

  const onClearUpload = () => setCoverImage(null)

  const formatInitialDateTime = (initialDate: any) => {
    const initialDateObj = new Date(initialDate)
    const formattedDate = initialDateObj.toISOString().split("T")[0]
    const formattedTime = initialDateObj
      .toTimeString()
      .split(" ")[0]
      .slice(0, 5)
    return { formattedDate, formattedTime }
  }

  const handleCreate = (coverImage?: NewFileInput) => {
    create({
      variables: {
        data: {
          title: event.title,
          cityId: city?.value ? +city.value : undefined,
          placeId: place?.value ? +place.value : undefined,
          latitude: event.latitude ? parseFloat(event.latitude) : undefined,
          longitude: event.longitude ? parseFloat(event.longitude) : undefined,
          categories: [+categories.value],
          endedDate: formatedDate(event.endedDate, event.endedTime),
          startedDate: formatedDate(event.startedDate, event.startedTime),
          startedTime: formatedDate(event.startedDate, event.startedTime),
          endedTime: formatedDate(event.endedDate, event.endedTime),
          descriptions: event.descriptions,
          numberPeopleMax: event.numberPeopleMax
            ? parseInt(event.numberPeopleMax)
            : undefined,
          isFree: pricing == "free" ? true : false,
          ticketPricings:
            ticketPricings.length && pricing !== "free"
              ? ticketPricings
              : undefined,
        },
        coverImage,
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/event/list")
      },
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "add",
          cache,
          entryData: data?.createEvent,
        })
      },
    })
  }

  const handleUpdate = (coverImage?: NewFileInput) => {
    update({
      variables: {
        data: {
          title: event.title,
          cityId: city?.value ? +city.value : undefined,
          placeId: place?.value ? +place.value : undefined,
          latitude: event.latitude ? parseFloat(event.latitude) : undefined,
          longitude: event.longitude ? parseFloat(event.longitude) : undefined,
          categories: [+categories.value],
          endedDate: formatedDate(event.endedDate, event.endedTime),
          startedDate: formatedDate(event.startedDate, event.startedTime),
          startedTime: formatedDate(event.startedDate, event.startedTime),
          endedTime: formatedDate(event.endedDate, event.endedTime),
          descriptions: event.descriptions,
          numberPeopleMax: event.numberPeopleMax
            ? parseInt(event.numberPeopleMax)
            : undefined,
          id,
          isFree: pricing == "free" ? true : false,
          ticketPricings:
            ticketPricings.length && pricing !== "free"
              ? ticketPricings.map(
                  ({
                    name,
                    price,
                    id,
                    isPublic,
                    nbAvailible,
                    descriptions,
                  }) => ({
                    name,
                    price,
                    id,
                    isPublic,
                    nbAvailible,
                    descriptions,
                  }),
                )
              : undefined,
        },
        coverImage,
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/event/list")
      },
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "update",
          cache,
          entryData: data?.updateEvent,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (file) {
      setIsLoading(true)
      uploadFile({
        cbError: ({ message }) => {
          setOpenSnackbar({
            message,
          })
          setIsLoading(false)
        },
        cbOk: (file) => {
          if (id) {
            handleUpdate(file)
          } else {
            handleCreate(file)
          }
        },
        file,
      })
    } else {
      if (id) {
        handleUpdate()
      } else {
        handleCreate()
      }
    }
  }

  useEffect(() => {
    if (id) {
      getEvent({
        variables: {
          where: {
            id,
          },
        },
      })
    }
  }, [id])

  if (loadingInit) return <Loader />

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Typography sx={{ textAlign: "center" }} variant="h1">
                  Ajouter un évenement
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  label="Titre"
                  name="title"
                  required
                  value={event.title}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <CustomListCategoryEvents
                  categoryeventsDefault={categories}
                  getCategoryEvents={setCategories}
                  required
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  type="date"
                  value={event.startedDate}
                  required
                  onChange={handleInputChange}
                  label="Date de debut"
                  fullWidth
                  variant="outlined"
                  name="startedDate"
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  type="time"
                  value={event.startedTime}
                  required
                  onChange={handleInputChange}
                  label="Heure de debut"
                  fullWidth
                  variant="outlined"
                  name="startedTime"
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  type="date"
                  value={event.endedDate}
                  required
                  onChange={handleInputChange}
                  label="Date de fin"
                  fullWidth
                  variant="outlined"
                  name="endedDate"
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  type="time"
                  value={event.endedTime}
                  required
                  onChange={handleInputChange}
                  label="Heure de fin"
                  fullWidth
                  variant="outlined"
                  name="endedTime"
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <CustomListCity
                  cityDefault={city}
                  getCity={setCity}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <CustomListPlace
                  getPlace={(value) => {
                    setPlace(value)
                    if (value.extraData) {
                      setEvent((prev) => ({
                        ...prev,
                        latitude: String(value.extraData.latitude),
                        longitude: String(value.extraData.longitude),
                      }))
                    }
                  }}
                  placeDefault={place}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  name="latitude"
                  label="Latitude"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={event.latitude}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  name="longitude"
                  label="Longitude"
                  fullWidth
                  type="number"
                  variant="outlined"
                  value={event.longitude}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={{ color: "white" }}
                  >
                    Prix
                  </FormLabel>
                  {ticketPricings.length ? (
                    <Button
                      onClick={() => setOpenPrice(true)}
                      variant="text"
                      color="primary"
                      sx={{ mt: 2 }}
                    >
                      Modifier
                    </Button>
                  ) : (
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pricing}
                      onChange={(el) => {
                        if (el.target.value === "paid") {
                          setOpenPrice(true)
                        }
                        setPricing(el.target.value)
                      }}
                      sx={{ color: "white" }}
                    >
                      <FormControlLabel
                        value={"free"}
                        control={<Radio />}
                        label="Gratuit"
                      />
                      <FormControlLabel
                        value="paid"
                        control={<Radio />}
                        label="Payant"
                      />
                    </RadioGroup>
                  )}
                </FormControl>
                <TicketPricingDialog
                  onClose={() => {
                    setOpenPrice(false)
                  }}
                  onSave={(pricing) => {
                    if (!pricing.length) {
                      setPricing("free")
                    }
                    setTicketPricings(pricing)
                  }}
                  open={openPrice}
                  ticketPricingsInit={ticketPricings}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  label="Nombre de personne maximum"
                  fullWidth
                  variant="outlined"
                  type="number"
                  onChange={handleInputChange}
                  name="numberPeopleMax"
                  value={event.numberPeopleMax}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomUploadFile
                  onClearUpload={onClearUpload}
                  onFileUpload={onCoverImageUpload}
                  label="Photo de couverture"
                  id="photo-de-couverture"
                  defaultUrl={defaulPhoto}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  multiline
                  minRows={3}
                  label="Déscription"
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  value={event.descriptions}
                  name="descriptions"
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || isLoading || loadingUpdate}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
