import * as React from "react"
import IconButton from "@mui/material/IconButton"
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

interface Props extends OutlinedInputProps {}

export default function CustomTextFieldPassword({
  value,
  name,
  label,
  required,
  onChange,
}: Props) {
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  return (
    <FormControl
      sx={{
        "& .MuiFormLabel-root": {
          color: "white",
        },
      }}
      fullWidth
      required={required}
      variant="outlined"
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        onChange={onChange}
        value={value}
        name={name}
        id={name}
        sx={{ color: "white"}}
        required={required}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  )
}
