import * as React from "react"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert, { AlertProps } from "@mui/material/Alert"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  selectSnackbarStatus,
  setCloseSnackbar,
} from "../../../store/features/snackbar/snackbarSlice"

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function CustomSnackbar() {
  const { open, message, status } = useAppSelector(selectSnackbarStatus)

  const dispatch = useAppDispatch()

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return
    }
    dispatch(setCloseSnackbar())
  }
  const isArray = Array.isArray(message) ? true : false
  return (
    <Snackbar
      draggable
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert
        draggable
        onClose={handleClose}
        severity={status}
        sx={{ width: "100%" }}
      >
        {!isArray ? (
          message
        ) : (
          <>
            <ul>
              <li>
                Veuillez voir ces erreurs:
                <ul>
                  {(message as string[])?.map((_el: string, index: any) => (
                    <li key={index}>{_el}</li>
                  ))}
                </ul>
              </li>
            </ul>
          </>
        )}
      </Alert>
    </Snackbar>
  )
}
