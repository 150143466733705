import { ApolloCache, Reference } from "@apollo/client"
import { NOTICEPLACE_FRAGMENT } from "../fragment/noticePlace"

export const updateCacheNoticePlace = ({
  cache,
  entryData,
  action,
}: {
  cache: ApolloCache<any>
  entryData: any
  action: "update" | "delete" | "add"
}) => {
  return cache.modify({
    fields: {
      noticePlaces(existing = []) {
        const newAdminRef = cache.writeFragment({
          data: entryData,
          fragment: NOTICEPLACE_FRAGMENT,
        })
        switch (action) {
          case "add":
            return [...existing, newAdminRef]
          case "delete":
            return [
              ...existing?.filter(
                (el: Reference | undefined) => el?.__ref !== newAdminRef?.__ref,
              ),
            ]
          case "update":
            const index = existing.findIndex(
              (el: Reference | undefined) => el?.__ref !== newAdminRef?.__ref,
            )
            const items = [...existing]
            if (index > -1) {
              items[index] = newAdminRef
            }
            return items
        }
      },
      aggregateNoticePlace(existing = {}) {
        let _all = existing?._count?._all
        switch (action) {
          case "add":
            return {
              ...existing,
              _count: {
                ...existing?._count,
                _all: _all ? _all + 1 : 0,
              },
            }
          case "delete":
            return {
              ...existing,
              _count: {
                ...existing?._count,
                _all: _all ? _all - 1 : 0,
              },
            }
        }
      },
    },
  })
}
