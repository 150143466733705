import { useCallback, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { ApolloError, useMutation, useQuery } from "@apollo/client"
import {
  selectEventCategoryPagination,
  setQueryEventCategory,
  setPaginationEventCategory,
  selectEventCategoryQuery,
} from "../../../store/features/eventCategory/eventCategorySlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  SortOrder,
  Event,
  QueryMode,
  InvitationStatus,
} from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import CustomImage from "../../Common/CustomImage/CustomImage"
import SwitchButton from "../../Common/SwitchButton/SwitchButton"
import { getValue } from "../../../utils/methodeString"
import moment from "moment"
import { updateCacheEvent } from "../../../caches/updateCacheEvent"
import { Chip } from "@mui/material"
import AddControllers from "./AddControllers/AddControllers"

export const LIST_EVENTS = graphql(`
  query Events(
    $where: EventWhereInput
    $aggregateEventWhere2: EventWhereInput
    $orderBy: [EventOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    events(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      latitude
      longitude
      numberPeopleMax
      isFreeEvent
      isEventCommissionPaid
      title
      isPublish
      isValidate
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      isFree
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
    aggregateEvent(where: $aggregateEventWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_EVENT = graphql(`
  mutation DeleteOneEvent($where: EventWhereUniqueInput!) {
    deleteOneEvent(where: $where) {
      id
      title
    }
  }
`)

const VALIDATE_EVENT = graphql(`
  mutation ValidateEvent($eventId: Float!, $isValidate: Boolean!) {
    validateEvent(eventId: $eventId, isValidate: $isValidate) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      isFree
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const PUBLISH_EVENT = graphql(`
  mutation PublishEvent($eventId: Float!, $isPublish: Boolean!) {
    publishEvent(eventId: $eventId, isPublish: $isPublish) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      isFree
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyEvent($where: EventWhereInput) {
    deleteManyEvent(where: $where) {
      count
    }
  }
`)

const ListEvent = () => {
  const [event, setEvent] = useState<Event | null>(null)
  const [open, setOpen] = useState(false)
  const { page, size } = useAppSelector(selectEventCategoryPagination)
  const query = useAppSelector(selectEventCategoryQuery)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [ids, setIds] = useState<number[]>([])

  const handleClose = () => setOpen(false)

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const [deleteMany] = useMutation(DELETE_MANY)

  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ces évenements ?",
        isLoading: false,
      }),
    )
  }

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [{ title: { contains: query, mode: QueryMode.Insensitive } }],
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_EVENTS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: { title: SortOrder.Asc },
      where: {
        ...queryName(),
      },
    },
  })

  const [deleteEvent] = useMutation(DELETE_EVENT)
  const [publishEvent] = useMutation(PUBLISH_EVENT)
  const [validateEvent] = useMutation(VALIDATE_EVENT)

  const onCompleted = () => {
    dispatch(
      setOpenSnackbar({
        message: "La modification a été faite avec succès",
        status: "success",
      }),
    )
    dispatch(setLoadingDelete(false))
    dispatch(setCloseAlert())
  }

  const onError = (err: ApolloError) => {
    const message = getErrorsAsString(err)
    dispatch(setCloseAlert())
    dispatch(setOpenSnackbar({ message }))
  }

  const handleTooglePublish = (id: number, isPublish: boolean) => {
    dispatch(setLoadingDelete(true))
    publishEvent({
      variables: {
        eventId: id,
        isPublish: !isPublish,
      },
      onCompleted: onCompleted,
      onError: onError,
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "update",
          cache,
          entryData: data?.publishEvent,
        })
      },
    })
  }

  const handleToogleValidate = (id: number, isValidate: boolean) => {
    dispatch(setLoadingDelete(true))
    validateEvent({
      variables: {
        eventId: id,
        isValidate: !isValidate,
      },
      onCompleted: onCompleted,
      onError: onError,
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "update",
          cache,
          entryData: data?.validateEvent,
        })
      },
    })
  }

  const handleShowAlertPublish = (
    id: number,
    isPublish: boolean,
    isFreeEvent: boolean,
    isValidate: boolean,
  ) => {
    if (!isValidate) {
      dispatch(
        setOpenSnackbar({
          message:
            "Cet événement doit être validé pour être publié, veuillez donc le valider avant de le publier.",
        }),
      )
    } else if (isFreeEvent) {
      dispatch(
        setOpenSnackbar({
          message:
            "Vous ne pouvez pas l'exonérer pour un événement gratuit. Veuillez contacter l'organisateur afin qu'il paie la commission de création de l'événement, afin que vous puissiez le publier.",
        }),
      )
    } else {
      dispatch(
        setOpenAlert({
          handleValid: () => handleTooglePublish(id, isPublish),
          message:
            "Êtes-vous vraiment sûr de vouloir modifier la statut de publication de cet événement ?",
          isLoading: false,
        }),
      )
    }
  }

  const handleShowAlertValidate = (id: number, isValidate: boolean) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleToogleValidate(id, isValidate),
        message:
          "Êtes-vous vraiment sûr de vouloir modifier la statut de validation de cet événement ?",
        isLoading: false,
      }),
    )
  }

  const handleAddController = (event: Event) => {
    setEvent(event)
    setOpen(true)
  }

  const columns = useMemo<MRT_ColumnDef<Event>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <Chip
              label="Ajouter"
              variant="outlined"
              onClick={() => handleAddController(row)}
            />
          </>
        ),
        header: "Ajouter des controllers",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <p>
            {row.mediators
              .filter((el) => el.invitationStatus === InvitationStatus.Valid)
              .map((el) => el.user.id)
              .toString()}
          </p>
        ),
        header: "Contrôleurs validés",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <p>
            {row.mediators
              .filter(
                (el) => el.invitationStatus === InvitationStatus.Inprogress,
              )
              .map((el) => el.user.id)
              .toString()}
          </p>
        ),
        header: "Contrôleurs en attente",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isPublish)}
              handleChange={() =>
                handleShowAlertPublish(
                  row.id,
                  Boolean(row.isPublish),
                  Boolean(row.isFreeEvent),
                  Boolean(row.isValidate),
                )
              }
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de publication",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isValidate)}
              handleChange={() =>
                handleShowAlertValidate(row.id, Boolean(row.isValidate))
              }
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de validation",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>{row.isEventCommissionPaid ? "Facturé" : "Non facturé"}</>
        ),
        header: "Commission Hub",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => <>{row.isFreeEvent ? "Gratuit" : "Payant"}</>,
        header: "Type d'événement",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row?.creator?.name,
        header: "Organisateur",
      },
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorKey: "title",
        header: "Nom",
      },
      {
        accessorFn: (row) => row?.city?.name_fr,
        header: "Ville",
      },
      {
        accessorFn: (row) => getValue(row?.place?.name),
        header: "Lieu",
      },

      {
        accessorFn: (row) => moment(row?.startedDate).format("DD/MMM/YYYY"),
        header: "Date de début",
      },
      {
        accessorFn: (row) => moment(row?.startedTime).format("LT"),
        header: "Heure de début",
      },
      {
        accessorFn: (row) => moment(row?.endedDate).format("DD/MMM/YYYY"),
        header: "Date de fin",
      },
      {
        accessorFn: (row) => moment(row?.endedTime).format("LT"),
        header: "Heure de fin",
      },
      {
        accessorFn: (row) =>
          row.categories && row.categories.length
            ? row.categories[0].name
            : null,
        header: "Catégorie",
      },
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.coverImage?.url || ""}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: "Image de couverture",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "latitude",
        header: "Latitude",
      },
      {
        accessorKey: "longitude",
        header: "Longitude",
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationEventCategory(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteEvent({
      variables: {
        where: { id },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "L'évenement a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "delete",
          cache,
          entryData: data?.deleteOneEvent,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer cet évenement ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setQueryEventCategory(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier un évenement"))
    navigate(`/event/edit/${id}`)
  }

  if (loading && !query) return <Loader />

  return (
    <>
      <CustomTable
        columns={columns}
        data={data?.events || []}
        lableAddNew="Créer un nouveau évenement"
        rootLisName={"/event/add"}
        isLoading={loading}
        showProgressBars={loading}
        rowCount={data?.aggregateEvent._count?._all || 0}
        handleChangePagination={handleChangePagination}
        pageIndex={page}
        pageSize={size}
        searchValue={query}
        onChangeSearchValue={onChangeSearchValue}
        handleDelete={handleOpenDeleteDialog}
        handleEdit={handleEdit}
        handleSelected={handleSelected}
        handleDeleteGroup={handleDeleteGroup}
        selectedIds={ids}
      />
      {event && (
        <AddControllers event={event} handleClose={handleClose} open={open} />
      )}
    </>
  )
}

export default ListEvent
