import { CardinalDirections } from "@mui/x-charts"
import { PieChart as Pie, pieArcLabelClasses } from "@mui/x-charts/PieChart"

interface Data {
  label: string
  value: number
  color: string
}

interface Props {
  data: Data[]
  margin?: Partial<CardinalDirections<number>> | undefined
}

function PieChart({ data, margin }: Props) {
  return (
    <Pie
      margin={margin}
      colors={data.map((item) => item.color)}
      series={[
        {
          arcLabel: (item) => `${item.value}`,
          arcLabelMinAngle: 10,
          data: data,
          highlightScope: { faded: "series", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          type: "pie",
        },
      ]}
      slotProps={{
        legend: {
          direction: "row",
          position: {
            vertical: "bottom",
            horizontal: "left",
          },
          padding: 10,
          labelStyle: {
            fontSize: 14,
            fill: "#000",
          },
          markGap: 1,
        },
      }}
      width={350}
      height={350}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
          fontWeight: "bold",
        },
      }}
    />
  )
}

export default PieChart
