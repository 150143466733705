import { Button, Grid, Paper, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheCountry } from "../../../caches/updateCacheCountry"
import Loader from "../../Common/Loader/Loader"

const GET_COUNTRY = graphql(`
  query Country($where: CountryWhereUniqueInput!) {
    country(where: $where) {
      id
      latitude
      longitude
      name_fr
      name_en
      name_mg
      name_wl
      name_dj
      ticketCommission
      paimentProMinPrice
      eventPrice
      currency
      alpha2
      alpha3
    }
  }
`)

const UPDATE_COUNTRY = graphql(`
  mutation UpdateCountry($data: CountryUpdateInputType!) {
    updateCountry(data: $data) {
      id
      latitude
      longitude
      eventPrice
      ticketCommission
      paimentProMinPrice
      currency
      name_fr
      name_en
      name_mg
      name_wl
      name_dj
      alpha2
      alpha3
    }
  }
`)

const CREATE_COUNTRY = graphql(`
  mutation CreateCountry($data: CountryCreateInputType!) {
    createCountry(data: $data) {
      id
      latitude
      longitude
      name_fr
      eventPrice
      ticketCommission
      paimentProMinPrice
      currency
      name_en
      name_mg
      name_wl
      name_dj
      alpha2
      alpha3
    }
  }
`)

export default function AddCountry() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [country, setCountry] = useState({
    name_fr: "",
    name_en: "",
    name_mg: "",
    name_wl: "",
    name_dj: "",
    alpha2: "",
    alpha3: "",
    latitude: "",
    longitude: "",
    eventPrice: "",
    currency: "",
    ticketCommission: "",
    paimentProMinPrice: "",
  })

  const [create, { loading }] = useMutation(CREATE_COUNTRY)
  const [update, { loading: loadingUpdate }] = useMutation(UPDATE_COUNTRY)

  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const handleInputChange = (event: any) => {
    setCountry({
      ...country,
      [event.target.name]: event.target.value,
    })
  }

  const [getCountry, { loading: loadingInit }] = useLazyQuery(GET_COUNTRY, {
    onCompleted(data) {
      const country = data?.country
      setCountry({
        name_fr: country?.name_fr || "",
        name_en: country?.name_en || "",
        alpha2: country?.alpha2 || "",
        alpha3: country?.alpha3 || "",
        name_mg: country?.name_mg || "",
        name_wl: country?.name_wl || "",
        name_dj: country?.name_dj || "",
        latitude: country?.latitude?.toString() || "",
        longitude: country?.longitude?.toString() || "",
        eventPrice: country?.eventPrice?.toString() || "",
        currency: country?.currency || "",
        ticketCommission: country?.ticketCommission?.toString() || "",
        paimentProMinPrice: country?.paimentProMinPrice?.toString() || "",
      })
    },
  })

  const handleCreate = () => {
    create({
      variables: {
        data: {
          ...country,
          latitude: country.latitude ? Number(country.latitude) : undefined,
          longitude: country.longitude ? Number(country.longitude) : undefined,
          eventPrice: country.eventPrice
            ? Number(country.eventPrice)
            : undefined,
          ticketCommission: country.ticketCommission
            ? Number(country.ticketCommission)
            : undefined,
          paimentProMinPrice: country.paimentProMinPrice
            ? Number(country.paimentProMinPrice)
            : undefined,
          currency: country.currency ? country.currency : undefined,
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
      },
      onCompleted: () => {
        navigate("/country/list")
      },
      update: (cache, { data }) => {
        updateCacheCountry({
          action: "add",
          cache,
          entryData: data?.createCountry,
        })
      },
    })
  }

  const handleUpdate = () => {
    update({
      variables: {
        data: {
          ...country,
          latitude: country.latitude ? Number(country.latitude) : undefined,
          longitude: country.longitude ? Number(country.longitude) : undefined,
          eventPrice: country.eventPrice
            ? Number(country.eventPrice)
            : undefined,
          ticketCommission: country.ticketCommission
            ? Number(country.ticketCommission)
            : undefined,
          paimentProMinPrice: country.paimentProMinPrice
            ? Number(country.paimentProMinPrice)
            : undefined,
          currency: country.currency ? country.currency : undefined,
          id,
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
      },
      onCompleted: () => {
        navigate("/country/list")
      },
      update: (cache, { data }) => {
        updateCacheCountry({
          action: "update",
          cache,
          entryData: data?.updateCountry,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (id) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }

  useEffect(() => {
    if (id) {
      getCountry({
        variables: {
          where: {
            id,
          },
        },
      })
    }
  }, [id])

  if (loadingInit) return <Loader />

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Typography sx={{ textAlign: "center" }} variant="h1">
                  Ajouter un pays
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name_fr"
                  label="Nom en Fr"
                  fullWidth
                  variant="outlined"
                  value={country.name_fr}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name_en"
                  label="Nom en En"
                  fullWidth
                  variant="outlined"
                  value={country.name_en}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name_mg"
                  label="Nom en MG"
                  fullWidth
                  variant="outlined"
                  value={country.name_mg}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name_dj"
                  label="Nom en DJ"
                  fullWidth
                  variant="outlined"
                  value={country.name_dj}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="name_wl"
                  label="Nom en WL"
                  fullWidth
                  variant="outlined"
                  value={country.name_wl}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <TextField
                  required
                  name="currency"
                  label="Devise"
                  fullWidth
                  variant="outlined"
                  value={country.currency}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="paimentProMinPrice"
                  required
                  label="Prix min de Paiment Pro"
                  fullWidth
                  variant="outlined"
                  value={country.paimentProMinPrice}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="eventPrice"
                  required
                  label="Prix pour la création d'évenement"
                  fullWidth
                  variant="outlined"
                  value={country.eventPrice}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="ticketCommission"
                  required
                  label="Commission de billetterie en pourcentage"
                  fullWidth
                  variant="outlined"
                  value={country.ticketCommission}
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="alpha2"
                  label="Code alpha2"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={country.alpha2}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="alpha3"
                  label="Code alpha3"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={country.alpha3}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="latitude"
                  label="Latitude"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={country.latitude}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="longitude"
                  label="Longitude"
                  fullWidth
                  type="number"
                  variant="outlined"
                  value={country.longitude}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || loadingUpdate}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
