import {
  decryptPermissions,
  decryptToken,
  decryptUserEmail,
  decryptUserName,
  encryptToken,
  encryptUserEmail,
  encryptUserName,
  encryptedPermissions,
} from "./encryptUser"

export const setAuthToken = (token: string) => {
  sessionStorage.setItem("token", encryptToken(token))
}

export const getAuthToken = () => {
  const token = sessionStorage.getItem("token")
  return token ? decryptToken(token) : ""
}

export const setUserId = (id: number) => {
  sessionStorage.setItem("id", id.toString())
}

export const getUserId = () => {
  const id = sessionStorage.getItem("id")
  return id ? parseInt(id) : 0
}

export const removeAuthToken = () => {
  sessionStorage.removeItem("token")
}

export const setUserName = (userName: string) => {
  sessionStorage.setItem("userName", encryptUserName(userName))
}

export const getUserName = () => {
  const userName = sessionStorage.getItem("userName")
  return userName ? decryptUserName(userName) : "Admin"
}

export const setUserEmail = (userEmail: string) => {
  sessionStorage.setItem("userEmail", encryptUserEmail(userEmail))
}

export const getUserEmail = () => {
  const userEmail = sessionStorage.getItem("userEmail")
  return userEmail ? decryptUserEmail(userEmail) : ""
}

export const removeUserName = () => {
  sessionStorage.removeItem("userName")
}

export const setPermissions = (permissions: string[]) => {
  sessionStorage.setItem("permissions", encryptedPermissions(permissions))
}

export const getPermissions = () => {
  const permissions = sessionStorage.getItem("permissions")
  return permissions ? decryptPermissions(permissions) : []
}

export const removePermissions = () => {
  sessionStorage.removeItem("permissions")
}

export const setUserCountryId = (countryId: string) => {
  sessionStorage.setItem("countryId", countryId)
}

export const removeUserCountryId = () => {
  sessionStorage.removeItem("countryId")
}

export const getUserCountryId = () => {
  const id = sessionStorage.getItem("countryId")
  return id ? parseInt(id) : 0
}
