import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"

const LIST_CATEGORYPLACES = graphql(`
  query ListCategoryPlace(
    $where: CategoryPlaceWhereInput
    $orderBy: [CategoryPlaceOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    categoryPlaces(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name
    }
  }
`)

interface Props {
  getCategoryPlace: (categoryplace: any) => void
  categoryplaceDefault: InfinityListItem | InfinityListItem[]
  label?: string
  required?: boolean
  multiple?: boolean
}
const CustomListCategoryPlace = ({
  getCategoryPlace,
  categoryplaceDefault,
  label = "Catégories",
  required = true,
  multiple = false,
}: Props) => {
  const [categoryplace, setCategoryPlace] = useState(categoryplaceDefault)

  const [queryCategoryPlace, setQueryCategoryPlace] = useState("")
  const [openCategoryPlace, setOpenCategoryPlace] = useState(false)
  const size = 500

  const queryName = useCallback(() => {
    const OrClause = {
      OR: [
        { name: { search: queryCategoryPlace, mode: QueryMode.Insensitive } },
        {
          name: { endsWith: queryCategoryPlace, mode: QueryMode.Insensitive },
        },
        {
          name: { contains: queryCategoryPlace, mode: QueryMode.Insensitive },
        },
        {
          name: {
            startsWith: queryCategoryPlace,
            mode: QueryMode.Insensitive,
          },
        },
      ],
    }
    return queryCategoryPlace ? OrClause : {}
  }, [queryCategoryPlace])

  const { loading, data, fetchMore } = useQuery(LIST_CATEGORYPLACES, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name: SortOrder.Asc },
      ...(queryCategoryPlace && {
        where: {
          ...queryName(),
        },
      }),
    },
  })

  const handleSearchCategory = (query: string) => {
    setQueryCategoryPlace(query)
  }

  const handleCloseCategory = () => {
    setOpenCategoryPlace(false)
  }
  const handleOpenCategoryPlace = async () => {
    setOpenCategoryPlace(true)
  }

  const onEndReachedCategory = () => {
    fetchMore({
      variables: {
        skip: data?.categoryPlaces.length,
      },
    })
  }

  useEffect(() => {
    if (categoryplace && categoryplaceDefault) {
      if (
        !Array.isArray(categoryplace) &&
        !Array.isArray(categoryplaceDefault)
      ) {
        if (!categoryplace?.value && categoryplaceDefault?.value) {
          setCategoryPlace(categoryplaceDefault)
        }
      } else {
        setCategoryPlace(categoryplaceDefault)
      }
    }
  }, [categoryplace, categoryplaceDefault])

  const dataCategoryPlace = data?.categoryPlaces

  const categoryplaces =
    loading || !dataCategoryPlace
      ? []
      : dataCategoryPlace.map((el) => ({ label: el.name, value: el.id }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseCategory}
      handleOpen={handleOpenCategoryPlace}
      open={openCategoryPlace}
      defaultValue={categoryplaceDefault}
      keyName="label"
      label={label}
      onSearch={handleSearchCategory}
      options={openCategoryPlace ? categoryplaces : []}
      loading={loading}
      multiple={multiple}
      onChange={(value) => {
        if (value) {
          setCategoryPlace(value)
          getCategoryPlace(value)
        }
      }}
      query={queryCategoryPlace}
      value={categoryplace}
      onEndReached={onEndReachedCategory}
      required={required}
    />
  )
}

export default CustomListCategoryPlace
