import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface EventNoticePlace {
  pagination: Pagination
  query: string
}

const initialState: EventNoticePlace = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const noticePlaceSlice = createSlice({
  name: "noticePlace",
  initialState,
  reducers: {
    setPaginationNoticePlace: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setQueryNoticePlace: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})

export const selectNoticePlacePagination = (state: RootState) =>
  state.noticePlace.pagination
export const selectNoticePlaceQuery = (state: RootState) =>
  state.noticePlace.query
export const { setPaginationNoticePlace, setQueryNoticePlace } =
  noticePlaceSlice.actions
export default noticePlaceSlice.reducer
