/* eslint-disable no-empty-pattern */
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

interface SnackbarState {
  open?: boolean
  message?: string | string[]
  status?: "error" | "success" | "warning" | "info"
}

const initialState: SnackbarState = {
  open: false,
  message: "",
  status: "error",
}

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setOpenSnackbar: (
      state,
      action: PayloadAction<SnackbarState | undefined>,
    ) => {
      return {
        ...state,
        ...action?.payload,
        open: true,
      }
    },
    setCloseSnackbar: (state) => {
      state.open = false
    },
  },
})

export const { setOpenSnackbar, setCloseSnackbar } = snackbarSlice.actions
export const selectSnackbarStatus = (state: RootState) => state.snackbar

export default snackbarSlice.reducer
