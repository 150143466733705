import { ApolloError } from "@apollo/client"

export function getErrorsAsString(
  error: ApolloError | undefined,
): string | string[] {
  if (error) {
    const message = error.message
    if (message.includes("Unique constraint")) {
      return "Veuillez vérifier ces valeurs, car une ou plusieurs d'entre elles existent déjà."
    } else if (message) {
      return message
    }
  }
  return "Une erreur s'est produite. Veuillez réessayer, s'il vous plaît."
}
