import { gql } from "@apollo/client"

export const CITY_FRAGMENT = gql(`
  fragment CityFragment on City {
    id
    name_fr
    name_en
    name_mg
    name_wl
    name_dj
    latitude
    longitude
    country {
      id
      name_fr
    }
  }
`)
