import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { getMainDefinition } from "@apollo/client/utilities"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { SentryLink } from "apollo-link-sentry"
import config from "../config/config"
import { getAuthToken } from "../utils/authToken"
import { getNativatorLanguage } from "../utils/language"

const client = () => {
  // Get the first part of the current url

  const token = getAuthToken()

  const httpLink = createHttpLink({
    uri: config.GRAPHQL_URL,
  })
  const locale = getNativatorLanguage() || "en"

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        locale,
      },
    }
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: config.GRAPHQL_WS_URL,
      connectionParams: {
        authToken: token,
        locale,
      },
      retryAttempts: Infinity,
      shouldRetry: () => true,
      keepAlive: 10000,
    }),
  )

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      )
    },
    wsLink,
    authLink.concat(httpLink),
  )
  return new ApolloClient({
    link: ApolloLink.from([new SentryLink(), splitLink]),
    cache: new InMemoryCache({}),
  })
}

export default client
