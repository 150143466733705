/* eslint-disable no-empty-pattern */
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

interface MenuState {
  open: boolean
  title: string
}

const initialState: MenuState = {
  open: true,
  title: "Tableau de board",
}

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    toogleOpen: (state) => {
      state.open = !state.open
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload
    },
  },
})

export const { toogleOpen, setTitle, setOpen } = menuSlice.actions
export const selectMenuStatus = (state: RootState) => state.menu.open
export const selectMenuTitle = (state: RootState) => state.menu.title

export default menuSlice.reducer
