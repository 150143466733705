import "./App.css"
import React from "react"
import theme from "./theme"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import CustomSnackbar from "./components/Common/CustomSnackbar/CustomSnackbar"
import CustomAlert from "./components/Common/CustomAlert/CustomAlert"
import Router from "./Router"

type Mode = "light" | "dark"

function App() {
  let mode = "light" as Mode
  const customTheme = React.useMemo(() => theme(mode), [mode])
  return (
    <ThemeProvider theme={customTheme}>
      <Router />
      <CustomSnackbar />
      <CustomAlert />
    </ThemeProvider>
  )
}

export default App
