import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface CustomerState {
  pagination: Pagination
}

const initialState: CustomerState = {
  pagination: {
    page: 0,
    size: 10,
  },
}

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
  },
})
export const selectCustomerPagination = (state: RootState) =>
  state.customer.pagination
export const { setCustomerPagination } = customerSlice.actions
export default customerSlice.reducer
