import { useNavigate } from "react-router-dom"
import { getAuthToken } from "../../utils/authToken"

interface Props {
  children: React.ReactNode
  isProtected?: boolean
}

export function ProtectedRoute({ children, isProtected = true }: Props) {
  const navigate = useNavigate()
  const token = getAuthToken()

  if (isProtected && !token) {
    setTimeout(() => navigate("/login"), 0)
    return null
  }

  if (!isProtected && token) {
    setTimeout(() => navigate("/"), 0)
    return null
  }

  return children
}
