import { Button, Grid, Paper, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import CustomUploadFile, {
  MinTypeFile,
} from "../../Common/CustomUploadFile/CustomUploadFile"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useAppDispatch } from "../../../store/app/hooks"
import { useNavigate, useParams } from "react-router-dom"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import Loader from "../../Common/Loader/Loader"
import { NewFileInput } from "../../../gql/graphql"
import { uploadFiles } from "../../../utils/uploadFiles"
import { updateCacheCategoryPlace } from "../../../caches/updateCacheCategoryPlace"

const GET_CATEGORY = graphql(`
  query FindFirstCategoryPlace($where: CategoryPlaceWhereInput) {
    findFirstCategoryPlace(where: $where) {
      id
      name
      name_en
      name_mg
      name_wl
      name_dj
      icon {
        url
        id
      }
      coverImage {
        url
        id
      }
    }
  }
`)

const UPDATE_CATEGORY = graphql(`
  mutation UpdateCategoryPlace(
    $data: CategoryPlaceUpdateInputType!
    $icon: NewFileInput
    $coverImage: NewFileInput
  ) {
    updateCategoryPlace(data: $data, icon: $icon, coverImage: $coverImage) {
      id
      name
      name_en
      name_mg
      name_wl
      name_dj
      icon {
        url
        id
      }
      coverImage {
        url
        id
      }
    }
  }
`)

const CREATE_CATEGORY = graphql(`
  mutation CreateCategoryPlace(
    $data: CategoryPlaceCreateInputType!
    $icon: NewFileInput
    $coverImage: NewFileInput
  ) {
    createCategoryPlace(data: $data, icon: $icon, coverImage: $coverImage) {
      id
      name
      name_en
      name_mg
      name_wl
      name_dj
      icon {
        id
        url
      }
      coverImage {
        id
        url
      }
    }
  }
`)

export default function AddPlaceCategory() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [icon, setIcon] = useState<File | null>(null)
  const [coverImage, setCoverImage] = useState<File | null>(null)
  const [name, setName] = useState<string>("")

  const [name_en, setNameEn] = useState<string>("")
  const [name_mg, setNameMg] = useState<string>("")
  const [name_wl, setNameWl] = useState<string>("")
  const [name_dj, setNameDj] = useState<string>("")

  const [defaulPhotoCoverImage, setDefaulPhotoCoverImage] = useState<
    MinTypeFile | undefined
  >(undefined)
  const [defaulPhotoIcon, setDefaulPhotoIcon] = useState<
    MinTypeFile | undefined
  >(undefined)

  const [create, { loading }] = useMutation(CREATE_CATEGORY)
  const [update, { loading: loadingUpdate }] = useMutation(UPDATE_CATEGORY)

  const onFileUploadIcon = (file: File) => {
    setIcon(file)
  }

  const onClearUploadIcon = () => setIcon(null)

  const onFileUploadCoverImage = (file: File) => {
    setCoverImage(file)
  }

  const onClearUploadCoverImage = () => setCoverImage(null)

  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const [getCategory, { loading: loadingInit }] = useLazyQuery(GET_CATEGORY, {
    onCompleted(data) {
      const newCategory = data.findFirstCategoryPlace
      setName(newCategory?.name || "")

      setNameEn(newCategory?.name_en || "")
      setNameMg(newCategory?.name_mg || "")
      setNameDj(newCategory?.name_dj || "")
      setNameWl(newCategory?.name_wl || "")

      if (newCategory?.coverImage) {
        const { url, id } = newCategory.coverImage
        setDefaulPhotoCoverImage({ url, id })
      }
      if (newCategory?.icon) {
        const { url, id } = newCategory.icon
        setDefaulPhotoIcon({ url, id })
      }
    },
  })

  const handleCreate = ({
    coverImage,
    icon,
  }: {
    coverImage?: NewFileInput
    icon?: NewFileInput
  }) => {
    create({
      variables: {
        data: { name },
        coverImage,
        icon,
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted() {
        setIsLoading(false)
        navigate("/place-category/list")
      },
      update: (cache, { data }) => {
        updateCacheCategoryPlace({
          action: "add",
          cache,
          entryData: data?.createCategoryPlace,
        })
      },
    })
  }

  const handleUpdate = ({
    coverImage,
    icon,
  }: {
    coverImage?: NewFileInput
    icon?: NewFileInput
  }) => {
    update({
      variables: {
        data: { name, id, name_dj, name_en, name_mg, name_wl },
        coverImage,
        icon,
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted() {
        setIsLoading(false)
        navigate("/place-category/list")
      },
      update: (cache, { data }) => {
        updateCacheCategoryPlace({
          action: "update",
          cache,
          entryData: data?.updateCategoryPlace,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const filesInput = [coverImage, icon].filter(
      (el): el is File => el !== null && el !== undefined && !!el?.name,
    )
    uploadFiles({ files: filesInput })
      .then((files) => {
        const coverImageData = files.length && coverImage ? files[0] : undefined
        const iconData = icon
          ? files.length
            ? files.length == 2
              ? files[1]
              : files[0]
            : undefined
          : undefined

        const inputData = { coverImage: coverImageData, icon: iconData }
        if (id) {
          handleUpdate(inputData)
        } else {
          handleCreate(inputData)
        }
      })
      .catch((error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (id) {
      getCategory({
        variables: {
          where: {
            id: {
              equals: id,
            },
          },
        },
      })
    }
  }, [id])

  if (loadingInit) return <Loader />

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Typography sx={{ textAlign: "center" }} variant="h1">
                  Ajouter une catégorie de lieux
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required={true}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label="Nom en Fr"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required={true}
                  value={name_en}
                  onChange={(e) => setNameEn(e.target.value)}
                  label="Nom en En"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required={true}
                  value={name_mg}
                  onChange={(e) => setNameMg(e.target.value)}
                  label="Nom en Mg"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required={true}
                  value={name_dj}
                  onChange={(e) => setNameDj(e.target.value)}
                  label="Nom en Dj"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required={true}
                  value={name_wl}
                  onChange={(e) => setNameWl(e.target.value)}
                  label="Nom en Wl"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomUploadFile
                  onClearUpload={onClearUploadCoverImage}
                  onFileUpload={onFileUploadCoverImage}
                  label="Photo de couveture"
                  id="photo-de-couveture"
                  defaultUrl={defaulPhotoCoverImage}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomUploadFile
                  onClearUpload={onClearUploadIcon}
                  onFileUpload={onFileUploadIcon}
                  label="Icône"
                  id="icon-upload-file"
                  defaultUrl={defaulPhotoIcon}
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || isLoading || loadingUpdate}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
